<template>
  <div class="main">
    <div class="auth-wrap">
      <div class="top">
        <!-- <van-icon name="cross" @click="authShow = false" /> -->
        <span>个人信息授权</span>
      </div>
      <div class="content">
        <div class="img-wrap">
          <img v-for="(img, i) in pdfImages" :key="i" :src="img" />
        </div>
      </div>
      <div class="btn page-btn">
        <van-button
          class="agree-btn"
          size="large"
          :loading="sendloading"
          color="#1890FF"
          type="info"
          :disabled="!!count"
          @click="sendAuthCode"
        >
          我已阅读并同意协议内容{{ `${count ? `（${count}s）` : ""}` }}
        </van-button>
      </div>
    </div>

    <!-- 短信验证 -->
    <van-popup class="verify-pop" v-model="verifyShow">
      <div class="verify-mask">
        <div class="verify-wrap">
          <div class="top">
            <van-icon name="cross" @click="verifyShow = false" />
            <span>短信验证</span>
          </div>
          <div class="content">
            <div class="tips">
              为确认以上操作为本人授权，需进行短信验证码校验，系统已给您发送验证码短信，请在下方输入短信验证码。
            </div>
            <div class="phone">
              <p>验证码已发送至</p>
              <p>{{ cacheData.phone | stringHypose }}</p>
            </div>
            <!-- 数字输入 -->
            <van-password-input
              :value="codevalue"
              :focused="focused"
              gutter="4px"
              :mask="false"
              @focus="inputFocus"
            />
            <div
              :class="['regain', regaincount ? 'dis' : '']"
              @click="regainFn"
            >
              重新获取验证码{{ `${regaincount ? `（${regaincount}s）` : ""}` }}
            </div>
          </div>
          <div class="btn page-btn">
            <van-button
              class="verify-btn"
              size="large"
              :loading="verifyloading"
              color="#1890FF"
              type="info"
              @click="verifyFn"
            >
              确认本人授权
            </van-button>
          </div>
        </div>

        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @input="onInput"
          @delete="onDelete"
          @blur="showKeyboard = false"
        />
      </div>
    </van-popup>
  </div>
</template>
<script>
import * as PDFJS from "pdfjs-dist";
let pdfjsWorker = require("pdfjs-dist/build/pdf.worker.min");
PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import {
  authCode,
  checkedAuthCode,
  personApplyPdf,
  orderDetailLatestv2
} from "@/api";
export default {
  data() {
    return {
      pdfImages: [],
      sendloading: false,
      cacheData: {},
      bizNo: "",
      count: 5,
      ossUrl: "",
      regaincount: 90,
      verifyloading: false,
      showKeyboard: false,
      focused: false, //
      codevalue: "", // 验证码
      verifyShow: false
    };
  },
  mounted() {
    const data = localStorage.getItem("_app_cache_data_") || "{}";

    this.cacheData = JSON.parse(data);
    // this.bizNo = this.cacheData.bizNo || "";
    // this.getPdfUrl();
    this.judgeShowAuthDlg();
  },
  methods: {
    async judgeShowAuthDlg() {
      try {
        const { productNo, merchantNo } = this.cacheData;
        await this.refreshCacheData(productNo, merchantNo);
        const { bizNo, hasAuth } = this.cacheData;
        if (hasAuth == "Y") {
          this.$router.replace(`/index?bizNo=${bizNo}`);
          return;
        }
        this.bizNo = bizNo || "";
        if (this.bizNo)
          this.$eventbus.$emit("verifyFollowTarget", {
            bizNo,
            callback: (res) => {
              if (res && res == "VERIFIED") {
                this.getPdfUrl();
              }
            }
          });
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 获取pdf
    async getPdfUrl() {
      try {
        if (!this.bizNo) throw "订单编号错误";
        this.$toast.loading({
          message: "加载授权书...",
          forbidClick: true
        });
        const res = await personApplyPdf(this.bizNo);
        this.ossUrl = res.data || "";
        this.pdfImages = await this.loadPDFSetImages();
        this.openAuth();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // load pdf to images
    async loadPDFSetImages() {
      try {
        let url = this.ossUrl;
        if (!url) throw "申请书地址错误";
        this.pdfLoading = true;
        return new Promise((resp, rej) => {
          let self = this;
          self.pdfPicturePath = [];
          let arr = [];
          try {
            const loadingTask = PDFJS.getDocument(url);
            loadingTask.promise
              .then((pdf) => {
                if (pdf) {
                  // pdf 总页数
                  const pageNum = pdf.numPages;
                  for (let i = 1; i <= pageNum; i++) {
                    pdf.getPage(i).then((page) => {
                      const canvas = document.createElement("canvas");
                      const ctx = canvas.getContext("2d");
                      const viewport = page.getViewport({ scale: 2 });
                      canvas.height = viewport.height;
                      canvas.width = viewport.width;
                      const destWidth = 750;
                      canvas.style.width = destWidth + "px";
                      canvas.style.height =
                        destWidth * (viewport.height / viewport.width) + "px";
                      page.render({
                        canvasContext: ctx,
                        viewport
                      });
                      setTimeout(() => {
                        let url = canvas.toDataURL("image/png");
                        arr.push(url);
                        if (i === pageNum) {
                          self.pdfLoading = false;
                          resp(arr);
                        }
                      }, 1000);
                    });
                  }
                }
              })
              .catch((e) => {
                console.error("Error: " + e.message || e);
                self.pdfLoading = false;
                rej("Error: " + e.message || e);
              });
          } catch (error) {
            console.error("Error: " + error.message || error);
            self.pdfLoading = false;
            rej("Error: " + error.message || error);
          }
        });
      } catch (error) {
        return Promise.reject(error.message || error);
      }
    },
    // 打开授权弹层
    openAuth() {
      if (this.count) {
        const ITV = setInterval(
          () => {
            this.count--;
            if (this.count <= 0) {
              this.count = 0;
              clearInterval(ITV);
            }
          },
          process.env.NODE_ENV === "development" ? 100 : 1000
        );
      }
    },
    // 发送授权验证码
    async sendAuthCode() {
      const { phone } = this.cacheData;
      try {
        this.sendloading = true;
        const { data } = await authCode({ mobile: phone, bizNo: this.bizNo });
        this.accountNo = data;
        this.$toast.success("验证码已发送，请注意查收！");
        this.verifyShow = true;
        this.setVerifyItv();
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.sendloading = false;
      }
    },
    // 提交授权验证码
    async verifyFn() {
      try {
        if (!this.codevalue) {
          throw "请输入验证码";
        }
        this.verifyloading = true;
        await checkedAuthCode({
          phone: this.cacheData.phone,
          smsCode: this.codevalue,
          bizNo: this.bizNo,
          ossUrl: this.ossUrl,
          accountNo: this.accountNo
        });
        this.$toast.success("提交成功");
        // this.$router.push({
        //   path: "/success"
        // });
        // 从原来的申请成功页面跳转到

        const { productNo, merchantNo } = this.cacheData;

        await this.refreshCacheData(productNo, merchantNo);
        this.$router.push({
          path: `/index?bizNo=${this.bizNo}`
        });
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.codevalue = "";
        this.verifyloading = false;
      }
    },
    // 刷新cacheData缓存
    async refreshCacheData(productNo, merchantNo) {
      const orderDetailResp = await orderDetailLatestv2(productNo, merchantNo);

      if (orderDetailResp && orderDetailResp.data) {
        const {
          bizNo,
          hasAuth,
          customerName,
          certNo,
          hasIdentification,
          isIdentification
        } = orderDetailResp.data;

        let jsonData = {
          ...this.cacheData,
          bizNo,
          hasAuth,
          customerName,
          certNo,
          hasIdentification, // 是否已经四要素认证
          isIdentification // 是否需要四要素认证
        };
        await localStorage.setItem(
          "_app_cache_data_",
          JSON.stringify(jsonData)
        );
        const data = (await localStorage.getItem("_app_cache_data_")) || "{}";
        this.cacheData = JSON.parse(data);
      }
    },
    // 重发验证码
    regainFn() {
      if (this.regaincount) return;
      this.sendAuthCode();
    },
    // 倒计时
    setVerifyItv() {
      this.regaincount = 90;
      const ITV = setInterval(
        () => {
          this.regaincount--;
          if (this.regaincount == 0) {
            clearInterval(ITV);
          }
        },
        process.env.NODE_ENV === "development" ? 20 : 1000
      );
    },
    //
    inputFocus() {
      this.focused = true;
      this.showKeyboard = true;
    },
    onInput(key) {
      this.codevalue = (this.codevalue + key).slice(0, 6);
    },
    onDelete() {
      this.codevalue = this.codevalue.slice(0, this.codevalue.length - 1);
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  height: 100vh;
}
.auth-wrap {
  display: flex;
  height: 100%;
  flex-direction: column;
  .top {
    height: 96px;
    background: #ffffff;
    text-align: center;
    position: relative;
    line-height: 96px;
    border-bottom: 1px solid #efefef;
    .van-icon {
      font-size: 32px;
      position: absolute;
      left: 32px;
      top: 32px;
    }
    span {
      font-size: 36px;
      font-weight: 600;
      color: #333333;
    }
  }
  .content {
    flex: 1;
    overflow-y: auto;
    position: relative;
    .mask {
      width: 100%;
      background-color: #fff;
      opacity: 0.6;
      position: absolute;
      z-index: 10;
    }
    .img-wrap {
      width: 100%;
      position: absolute;
      z-index: 1;
      img {
        width: 100%;
      }
    }
  }
}
.verify-pop {
  background-color: transparent;
}
.verify-mask {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verify-wrap {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 622px;
  height: 748px;
  background: #ffffff;
  border-radius: 16px;
  .top {
    height: 96px;
    background: #ffffff;
    text-align: center;
    position: relative;
    line-height: 96px;
    border-bottom: 1px solid #efefef;
    .van-icon {
      font-size: 32px;
      position: absolute;
      left: 32px;
      top: 32px;
    }
    span {
      font-size: 36px;
      font-weight: 600;
      color: #333333;
    }
  }
  .content {
    flex: 1;
    overflow-y: auto;
    padding: 20px 32px;
    .tips {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
    }
    .phone {
      p {
        text-align: center;
        &:first-child {
          margin-top: 48px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        &:last-child {
          margin-top: 16px;
          font-size: 40px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
      }
    }
    .van-password-input {
      margin: 0;
      margin-top: 48px;
      .van-password-input__item {
        background: #f6f6f8;
        width: 86px;
        height: 86px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
      }
      .van-password-input__item--focus {
        background: #ffffff;
        border: 1px solid #1890ff;
        .van-password-input__cursor {
          background-color: #1890ff;
        }
      }
    }
    .regain {
      color: #1890ff;
      margin-top: 32px;
      text-align: center;
      &.dis {
        color: #888;
      }
    }
  }
  .page-btn {
    width: 100%;

    .verify-btn {
      width: 558px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>
